import RailsUjs from '@rails/ujs'
RailsUjs.start()

/* react components */
import Movie from '../components/service/Movie'
import Search from '../components/service/Search'
import Tags from '../components/service/Tags'
import EliminationProtectionModal from '../components/service/EliminationProtectionModal'

/* scripts */
import '../scripts/service/analytics.js'
import '../scripts/service/header.js'
import '../scripts/service/lazyload.js'
import '../scripts/service/modal.js'
import '../scripts/service/navigation.js'
import '../scripts/service/picturefill.js'
import '../scripts/service/recommendFooter.js'
import '../scripts/service/scroll.js'
import '../scripts/service/sticky.js'
import '../scripts/service/tagmanager.js'

/* utility */
import '../utility/service/format'
import '../utility/service/helper'
import '../utility/service/ua'
import '../utility/service/verification'
