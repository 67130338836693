import React, { useState, useEffect, useMemo } from 'react'
import WebpackerReact from 'webpacker-react'
/* data */
import { banners } from './banners'
/* style */
import { Overlay, Modal, CloseButton, Image } from './style'

const EliminationProtectionModal = ({ type = 'default', page = 'top' }) => {
  const [showModal, setShowModal] = useState(false)

  const banner = useMemo(() => banners.find((v) => v.type === type))

  /**
   * モーダル表示(ページを跨いで一度だけ表示)
   */
  const viewModal = () => {
    const displayedAlready = localStorage.getItem('displayedAlready')
    if (displayedAlready) return

    setShowModal(true)
    localStorage.setItem('displayedAlready', 'true')
  }

  /**
   * URLを生成
   */
  const generateUrl = () => {
    const utm_source = 'utm_source=codecampus'
    const utm_meidum = 'utm_medium=bounce_modal'
    const utm_content = `utm_content=${page}`
    const utm_campaign = `utm_campaign=${banner.campaignName}`

    return `${banner.linkUrl}?${utm_source}&${utm_meidum}&${utm_content}&${utm_campaign}`
  }

  useEffect(() => {
    document.addEventListener('mouseleave', () => {
      viewModal()
    })
  }, [])

  return (
    <>
      {showModal && (
        <Overlay>
          <Modal>
            <a href={generateUrl()} target="_blank">
              <Image src={banner.imageUrl} type={type} />
            </a>
            <CloseButton onClick={() => setShowModal(false)}>×</CloseButton>
          </Modal>
        </Overlay>
      )}
    </>
  )
}

export default WebpackerReact.setup({ EliminationProtectionModal })
