/**
 * Components/Service/Search
 * @package Components
 */
import React, { useState } from 'react'
import WebpackerReact from 'webpacker-react'

/**
 * @param props None
 * @returns
 */
const Search = () => {
  const [openFlg, setOpenFlg] = useState(false)

  /**
   * 検索バーを開く
   */
  const open = () => {
    if (openFlg) {
      return (location.href = `/search?word=${refs.input.value.replace(
        /[\s　]/g,
        '+'
      )}`)
    }
    setOpenFlg(true)
    return false
  }

  /**
   * 検索バーを閉じる
   */
  const close = () => {
    setOpenFlg(false)
    return false
  }

  return (
    <div className="c-search" data-open={openFlg}>
      <div className="c-search_open">
        <svg className="c-search_icon" onClick={open}>
          <title>検索窓を開く</title>
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#svgs-icon_search"
          ></use>
        </svg>
      </div>
      <div className="c-search_input">
        <form method="get" action="/search">
          <input
            type="text"
            name="word"
            className="c-search_inputText"
            placeholder="記事検索"
          />
        </form>
      </div>
      <div className="c-search_close" onClick={close}>
        <svg className="c-search_icon">
          <title>検索窓を閉じる</title>
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#svgs-icon_menu-close"
          ></use>
        </svg>
      </div>
    </div>
  )
}

export default WebpackerReact.setup({ Search })
