(() => {

  window.addEventListener('load', () => {
    let widgetObject  = document.getElementById('js-fixedHeader');
    let targetSection = document.getElementById('js-globalNavi');

    if (!widgetObject && !targetSection) return false;

    let scroll = targetSection.getBoundingClientRect().top + window.pageYOffset;

    window.addEventListener('scroll', () => {
      widgetObject.dataset.show = (window.pageYOffset > scroll);
    });
  });

})();
