import $ from 'jquery'

;(() => {
  const SPEED = 500
  const POSITION = 60

  // スムーススクロール
  $(() => {
    $("a[href^='#']").on('click', (event) => {
      let href = $(event.currentTarget).attr('href')
      let target = $(href == '#' || href == '' ? 'html' : href)
      let position = target.offset().top - POSITION
      $('html, body').animate({ scrollTop: position }, SPEED, 'swing')
      return false
    })
  })
})()
