export const banners = [
  {
    type: 'default',
    linkUrl: 'https://codecamp.jp',
    imageUrl:
      'https://codecamp-blog.s3.ap-northeast-1.amazonaws.com/banners/default.jpg',
    campaignName: 'default',
  },
  {
    type: 'engineerJobChange',
    linkUrl: 'https://codecamp.jp/courses/engineer',
    imageUrl:
      'https://codecamp-blog.s3.ap-northeast-1.amazonaws.com/banners/engineer_job_change.jpg',
    campaignName: '/cources/engineer',
  },
]
