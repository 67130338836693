import style from 'styled-components'

export const Overlay = style.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background:rgba(0,0,0,0.5);
  z-index: 15000;
`

export const Modal = style.div`
  position: relative;
`

export const CloseButton = style.button`
  position: absolute;
  top: -30px;
  right: -35px;
  height: 30px;
  text-align: center;
  font-size: 24px;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  background:rgba(0,0,0,0.001);

  &:hover {
    cursor: pointer;
  }
`

export const Image = style.img`
  width: ${(props) => (props.type === 'default' ? '300px' : '600px')};
`
