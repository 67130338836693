( () => {
  window.addEventListener('load', () => {
    // トリガー
    const trigger = document.querySelector('[data-hidden]');
    if (!trigger) return false;

    // コンテンツ全体の高さ
    const CONTENT_HEIGHT = document.body.offsetHeight;
    // ウィンドウの高さ
    const WINDOW_HEIGHT = window.innerHeight;
    // フッターの高さ
    const FOOTER_HEIGHT = document.getElementById('js-footer').clientHeight;
    // 非表示にするOFFSET
    const OFFSET_POINT = CONTENT_HEIGHT - WINDOW_HEIGHT - FOOTER_HEIGHT * 15;

    // スクロールイベント
    window.addEventListener('scroll', () => {
      // ページの最初と最後は表示しない
      const hideFlg = (window.pageYOffset > WINDOW_HEIGHT * 2.5 && window.pageYOffset < OFFSET_POINT) ? 'false' : 'true';
      trigger.setAttribute('data-hidden', hideFlg);
    }, false);
  }, false);
})();
