import 'jquery-sticky'
import $ from 'jquery'

;(() => {
  $(() => {
    $('.js-sticky').sticky({
      topSpacing: 61,
      bottomSpacing: 242,
      wrapperClassName: 'js-stickyWrapper',
    })
  })
})()
